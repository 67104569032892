import { BrowserRouter } from 'react-router-dom';
import Navigation from './nav/Navigation';

function App() {

  return (
      <Navigation/>
  );
}

export default App;
